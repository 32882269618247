





















































































































































































































































































































.el-textarea {
  .el-textarea__inner {
    min-height: 10rem !important;
    resize: none;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
